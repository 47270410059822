/**
 * 搜索头
 */
//  划拨记录
export const searchFileds = [
  {
    field: "sp_code",
    label: "服务商",
    type: "select",
    options: [],
    iw: 200
  },
  {
    field: "refuel_card_type",
    label: "账户类型",
    type: "select",
    options: [],
    iw: 200
  },
  {
    field: "driver_name",
    label: "收款司机",
    // type: "select-data-search",
    props: {
      placeholder: "请输入司机姓名"
    },
    type: "input",
    options: [],
    iw: 200
  },
  {
    field: "refuel_card_no",
    label: "划入卡号",
    type: "input",
    props: {
      placeholder: "请输入卡号"
    },
    options: [],
    iw: 200
  },
  {
    field: ["begin_trans_time", "end_trans_time"],
    label: "划拨时间",
    type: "dateRange",
    props: {
      placeholder: ["请选择开始时间", "请选择结束时间"],
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  }
];
