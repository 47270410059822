<template>
  <page-layout class="ma-24">
    <!-- 搜索头 -->
    <search-header
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
      style="padding-left: 0px;"
    />
    <!-- 导出按钮 -->

    <!-- 列表 -->
    <div class="bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        class="bg-white"
        dataKey="modal"
      >
        <p-t-column type="seq" width="60" title="序号" />
        <p-t-column field="transfer_no" width="240" title="划拨订单ID" />
        <p-t-column
          field="amount"
          min-width="120"
          title="订单余额（元）"
          v-slot="{ row }"
          >{{ (row.amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column field="sp_name" min-width="120" title="服务商" />
        <p-t-column
          field="refuel_card_type_name"
          min-width="120"
          title="账户类型"
        />
        <p-t-column field="driver_name" min-width="120" title="收款司机" />
        <p-t-column field="refuel_card_no" min-width="180" title="划入卡号" />
        <p-t-column
          field="trans_time"
          min-width="240"
          title="划拨时间"
          v-slot="{ row }"
        >
          <span>{{
            formatToDateTime(row.transfer_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </p-t-column>
        <p-t-column field="status_name" min-width="120" title="状态" />
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import useSearchForm from "@/hooks/UseSearchForm";
import useSearch from "@/hooks/useSearch";
import {
  useGasTransferPageApi,
  useEnumServiceProvideListApi,
  useEnumGasCardTypeListApi
} from "@/apis/oil";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  name: "transfer-record",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const tableDataSource = reactive(getInitTable());
    //服务商列表
    (async () => {
      const enumServiceProvideList = useEnumServiceProvideListApi();
      let serviceProvideList = await enumServiceProvideList();
      console.log(serviceProvideList);
      useSearch(searchFileds, serviceProvideList, "sp_code");
    })();
    //账户类型列表
    (async () => {
      const enumGasCardTypeList = useEnumGasCardTypeListApi();
      let enumGasCardTypeListArr = await enumGasCardTypeList();
      useSearch(searchFileds, enumGasCardTypeListArr, "refuel_card_type");
    })();
    //表格搜索相关
    const { filter, handleFormSearch } = useSearchForm(searchFileds);
    //表格数据接口
    const gasTransferPage = useGasTransferPageApi();
    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        //路由默认值
        if (route.query) {
          Object.keys(route.query).forEach(item => {
            if (!filter.value[item]) {
              filter.value[item] = route.query[item];
            }
          });
        }
        const data = {
          ...filter.value,
          // sort: "trans_time desc",
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await gasTransferPage(data);
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };
    return {
      searchFileds,
      getConsultingList,
      handleFormSearch,
      handleActionClick,
      dataSource: tableDataSource,
      formatToDateTime
    };
  }
});
</script>
